body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

.header {
  background: #005A9C;
  color: #FFFFFF;
  padding: 1rem 0;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
}

nav ul li a {
  color: #FFFFFF;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

nav ul li a:hover {
  color: #FF3B30;
}

.hero {
  background: linear-gradient(to right, #005A9C, #FF3B30);
  color: #FFFFFF;
  padding: 5rem 0;
  text-align: center;
}

.cta-btn {
  background-color: #FF3B30;
  color: #FFFFFF;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 2rem;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #cc2e27;
}

.about, .services, .pricing, .contact {
  padding: 3rem 0;
  background: #FFFFFF;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.service-item {
  flex: 1;
  min-width: 300px;
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.service-item h3 {
  color: #005A9C;
  margin-bottom: 1rem;
}

.service-item ul {
  list-style: none;
  padding: 0;
}

.service-item ul li {
  margin-bottom: 0.5rem;
}

.pricing ul {
  list-style: none;
  padding: 0;
}

.pricing ul li {
  margin: 1rem 0;
  font-weight: bold;
}

.popup-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container {
  background: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  position: relative;
}

.close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.form-step {
  margin-top: 1.5rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

input[type="text"], input[type="email"], select, textarea {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button.cta-btn {
  display: block;
  width: 100%;
}

footer {
  background: #005A9C;
  color: #FFFFFF;
  text-align: center;
  padding: 1rem 0;
}
